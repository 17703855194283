import '../components/styles/AboutUsStyles.css'

function AboutUs() {
	return (
		<div className="about-container">
			<h1>Our Mission</h1>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur harum dolore alias delectus numquam! Quo molestiae fugit ab voluptatem nam obcaecati atque minus expedita, reiciendis reprehenderit ratione ducimus eligendi rem!
				Reprehenderit commodi beatae deleniti a veritatis eligendi, possimus porro, recusandae aperiam animi obcaecati rem delectus officia. Voluptatibus nostrum ex distinctio mollitia tempore doloribus dignissimos. Quas sequi quaerat
				doloremque similique maiores. Quam voluptas ducimus rerum debitis voluptatem maxime consequuntur non itaque iste saepe, temporibus aut vitae illum adipisci in ut impedit fuga similique nobis natus quidem. Ea quidem provident non
				doloribus? Vitae, ut sunt! Eos officiis voluptates facere error tempora nulla labore animi harum fugit minima at vero quod, illo, consectetur facilis suscipit esse earum deserunt neque aspernatur exercitationem modi sint. Officia
				repudiandae ullam aperiam id dicta deserunt quasi, dolorum ut et hic rerum iste quis natus minus commodi voluptate dolor! Pariatur autem magnam molestias facilis sunt molestiae iure quas magni. Iste maxime aut sit sapiente,
				consequatur esse voluptatum dolor expedita quasi optio blanditiis magnam, adipisci natus dignissimos quam ratione sint. Accusantium corrupti id amet officia assumenda debitis eveniet magnam deserunt. Qui sit rerum commodi reiciendis,
				vero earum accusamus animi perferendis possimus id dolor quaerat consequuntur architecto illo ut quidem, odio praesentium porro obcaecati amet beatae quibusdam! Perspiciatis vitae eius sit!
			</p>
			<h1>Our Vision</h1>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur harum dolore alias delectus numquam! Quo molestiae fugit ab voluptatem nam obcaecati atque minus expedita, reiciendis reprehenderit ratione ducimus eligendi rem!
				Reprehenderit commodi beatae deleniti a veritatis eligendi, possimus porro, recusandae aperiam animi obcaecati rem delectus officia. Voluptatibus nostrum ex distinctio mollitia tempore doloribus dignissimos. Quas sequi quaerat
				doloremque similique maiores. Quam voluptas ducimus rerum debitis voluptatem maxime consequuntur non itaque iste saepe, temporibus aut vitae illum adipisci in ut impedit fuga similique nobis natus quidem. Ea quidem provident.
			</p>
			<h1>Our Products</h1>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur harum dolore alias delectus numquam! Quo molestiae fugit ab voluptatem nam obcaecati atque minus expedita, reiciendis reprehenderit ratione ducimus eligendi rem!
				Reprehenderit commodi beatae deleniti a veritatis eligendi, possimus porro, recusandae aperiam animi obcaecati rem delectus officia. Voluptatibus nostrum ex distinctio mollitia tempore doloribus dignissimos. Quas sequi quaerat.
			</p>
		</div>
	)
}

export default AboutUs
