export const MenuProduct = [
	{
		title: 'Home',
		url: '/',
		cName: 'nav-links',
		icon: 'fa-solid fa-house-user',
	},
	{
		title: 'Honey',
		url: '/honey',
		cName: 'nav-links',
		icon: 'fa-solid fa-honey-pot',
	},
	{
		title: 'Olive Oil',
		url: '/oliveoil',
		cName: 'nav-links',
		icon: 'fa-solid fa-oil-can',
	},
	{
		title: 'Wine',
		url: '/wine',
		cName: 'nav-links',
		icon: 'fa-solid fa-wine-glass',
	},
	{
		title: 'Olives',
		url: '/olives',
		cName: 'nav-links',
		icon: 'fa-solid fa-olive',
	},
	{
		title: 'Herbs',
		url: '/herbs',
		cName: 'nav-links',
		icon: 'fa-solid fa-leaf',
	},
]
